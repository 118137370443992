import React from 'react'
import {
  BrandImg,
  StyledTopPageTitle,
  WrapperBrandIcon,
} from './BrandIcon.style'
import brandList from './brandList'

const BrandIcon: React.FC<{
  name: string
  isInverted?: boolean
  width?: number
  isTabletLandscape?: boolean
}> = ({ name, isInverted, width, isTabletLandscape }) => {
  let parsedName = name
  if (name.toLowerCase() === 'bvlgari') {
    parsedName = name.replace('v', 'u')
  }

  const brand = brandList.find(
    (brand) =>
      brand.name.toLowerCase().replace('&', '') ===
      parsedName.toLowerCase().replace('&', '')
  )

  const isPradaLineaRossa = brand?.name === 'Prada Linea Rossa'
  const isScuderiaFerrari = brand?.name === 'Scuderia Ferrari'
  const isArmaniExchange = brand?.name === 'Armani Exchange'

  return brand ? (
    <WrapperBrandIcon width={width} isTabletLandscape={isTabletLandscape}>
      <BrandImg
        effect="opacity"
        src={brand.logo}
        alt={brand?.name}
        isInverted={isInverted!}
        isPradaLineaRossa={isPradaLineaRossa}
        isScuderiaFerrari={isScuderiaFerrari}
        isArmaniExchange={isArmaniExchange}
      />
    </WrapperBrandIcon>
  ) : (
    <WrapperBrandIcon>
      <StyledTopPageTitle>{name}</StyledTopPageTitle>
    </WrapperBrandIcon>
  )
}
export default BrandIcon
