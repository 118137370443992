import config from '@configs/index'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper } from 'swiper'
import { useAppDispatch } from '@hooks/redux'
import {
  StyledImageWrapper,
  StyledProductAnchor,
  StyledProductSubWrapper,
  StyledProductTileImageContainer,
  StyledProductTileWrapper,
  StyledProductVariants,
  StyledSquare,
} from './ProductTile.style'
import ProductImage, { ProductImageProps } from '../ProductImage/ProductImage'
import { setLastSelectedProduct } from '../../features/ui/action'
import useBreakpoints from '../../hooks/useBreakpoints'
import ProductTileGallery from './components/ProductTileGallery'
import ProductTileAlgoliaUtils from '@utils/ProductAlgolia/ProductTileAlgoliaUtils'
import useProductTile from '@components/ProductTile/useProductTile'
import ProductTileFooter from '@components/ProductTile/components/ProductTileFooter/ProductTileFooter'
import ProductTileHeader from '@components/ProductTile/components/ProductTileHeader'
import { ProductAlgolia } from '../../types/ProductAlgolia/ProductAlgolia'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import {
  StyledColorLabelContainer,
  StyledControlsWrapperColorLabel,
} from '@components/ProductTile/components/ProductTileGallery/ProductTileGallery.style'
import { setMocoOrderedIds } from 'src/features/product/slice'
import { IUiState } from '../../features/ui/slice'

interface ProductTileAlgoliaProps {
  product: ProductAlgolia
  tileIndex: number
  isLazyLoadEnabled?: boolean
  onClick?: () => void
  productListingLayout?: IUiState['productsMobileListingLayout']
}

const siteName = config.REACT_APP_STORENAME

const ProductTileAlgolia: React.FC<ProductTileAlgoliaProps> = ({
  product,
  tileIndex,
  isLazyLoadEnabled,
  onClick,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const { isDesktop } = useBreakpoints()

  const [carouselMode, setCarouselMode] = useState<boolean>(false)
  const { getProductAttributes, getLxProductTypes } = ProductAlgoliaUtils
  const {
    getTileDataElementId,
    getProductImageWidth,
    selectedClusterIndex,
    setSelectedClusterIndex,
    getClusterData,
    productsMobileListingLayout,
    productsMobileListingLayoutPages,
  } = useProductTile(product)

  const tileDataElementId = getTileDataElementId(tileIndex)
  const { clusters, clusterSelected, clusterLength } = getClusterData
  const attributes = clusterSelected.attributes_translated
  const productLinkTo = `/${langCode}${clusterSelected.url || ''}`

  const { colorLabel } =
    ProductTileAlgoliaUtils.getProductTileAttributes(attributes)

  const { lxSearchPageProductType, modelName, displaySku } =
    getProductAttributes(attributes)
  const { isFrames, isAccessories } = getLxProductTypes(lxSearchPageProductType)

  const isMoCoSliderRendered = isFrames
  const isMoCoSliderDisplayed =
    isMoCoSliderRendered &&
    (carouselMode || productsMobileListingLayoutPages === 'full')

  const onProductTileMouseEnter = () => {
    if (
      clusterLength > 0 &&
      (productsMobileListingLayoutPages === 'full' || isDesktop)
    ) {
      setCarouselMode(true)
    }
  }

  const onProductTileMouseLeave = () => setCarouselMode(false)

  const onImageClick = () =>
    dispatch(setLastSelectedProduct(product.productId || ''))

  const commonProductImageProps = {
    alt: t('ProductTile.AltPrefix', { name: modelName }),
    draggable: false,
    sequence: '1',
    usage: 'PLP' as 'PLP',
    width: getProductImageWidth,
    onClick: onImageClick,
  }

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: isAccessories ? '1' : '2',
    usage: 'PLP',
  }

  const onProductChange = (swiper: Swiper) => {
    if (Number.isInteger(swiper.realIndex)) {
      setSelectedClusterIndex(swiper.realIndex)
    }
  }

  const onProductTileClick = () => {
    dispatch(
      setMocoOrderedIds(
        product?.clusters.map((item) => item.parentProductId) ?? []
      )
    )
  }

  const metaData = {
    'data-element-id': tileDataElementId,
    'data-description': encodeURIComponent(
      `${siteName}_${modelName}_${displaySku}`
    ),
  }

  return (
    product && (
      <StyledProductAnchor
        to={productLinkTo}
        //isClRelatedTile={!isFrames}
        {...metaData}
      >
        <StyledProductTileWrapper
          onMouseEnter={onProductTileMouseEnter}
          onMouseLeave={onProductTileMouseLeave}
          onClick={onProductTileClick}
        >
          <StyledProductSubWrapper
            fullLayout={
              carouselMode || productsMobileListingLayoutPages === 'full'
            }
          >
            <StyledSquare onClick={onClick}>
              <ProductTileHeader clusterSelected={clusterSelected} />
              {isMoCoSliderRendered && (
                <ProductTileGallery
                  isDisplayed={isMoCoSliderDisplayed}
                  commonImageProps={commonGalleryProductImageProps}
                  currentProductIndex={selectedClusterIndex}
                  onProductChange={onProductChange}
                  cluster={clusters}
                />
              )}
              <StyledProductTileImageContainer
                isFull={productsMobileListingLayout === 'full'}
                isDisplayed={!isMoCoSliderDisplayed}
                isClRelatedTile={!isFrames}
              >
                <StyledImageWrapper shouldZoomOnHover={!isFrames}>
                  <ProductImage
                    attachments={clusterSelected?.attachments}
                    isLazyLoadEnabled={isLazyLoadEnabled}
                    {...commonProductImageProps}
                  />
                </StyledImageWrapper>
              </StyledProductTileImageContainer>

              {/* TODO: Sold out logic */}
              {/* {!soldOut && footerElementsDisplay?.colors && ( */}
              <StyledProductVariants isDisplayed={!isMoCoSliderDisplayed}>
                {clusterLength > 1 && isMoCoSliderRendered ? (
                  <StyledControlsWrapperColorLabel
                    aria-describedby={`${clusterLength} ${t(
                      'ProductTile.Labels.colors'
                    )}`}
                  >
                    {`${clusterLength} ${t('ProductTile.Labels.colors')}`}
                  </StyledControlsWrapperColorLabel>
                ) : (
                  <StyledColorLabelContainer>
                    <StyledControlsWrapperColorLabel
                      aria-describedby={`${colorLabel}`}
                    >{`${colorLabel}`}</StyledControlsWrapperColorLabel>
                  </StyledColorLabelContainer>
                )}
              </StyledProductVariants>
            </StyledSquare>
          </StyledProductSubWrapper>
          <ProductTileFooter
            clusterSelected={clusterSelected}
            metaData={metaData}
            isCompact={productsMobileListingLayoutPages !== 'full'}
          />
        </StyledProductTileWrapper>
      </StyledProductAnchor>
    )
  )
}

export { ProductTileAlgolia }
