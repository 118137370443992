import React from 'react'
import { HeartFilledIcon, HeartIcon } from '@components/UI/Icons'
import { IconButton, StyledLoader } from '@components/UI'
import { styled, useTheme } from '@mui/material'
import { Product } from 'src/types/product'
import { wishlistExternalIdSelector } from '../../../features/wishList/selector'
import { useDispatch, useSelector } from 'react-redux'
import {
  useAddItemToWishListMutation,
  useDeleteItemFromWishlistMutation,
  useGetWishListQuery,
  useLazyCreateWishListQuery,
} from '../../../features/wishList/query'
import { storageSessionHandler } from '../../../foundation/utils/storageUtil'
import { getGuestIdentity } from '../../../features/user/thunks'
import Log from '../../../services/Log'
import { ProductAlgolia } from '../../../types/ProductAlgolia/ProductAlgolia'

type ProductCombined =
  | Product
  | (ProductAlgolia & { id: string; partNumber: string })

interface IProductDetailsList {
  currentProduct: ProductCombined
}

export const WishlistIcon = styled(IconButton, {
  name: 'Wishlist',
  slot: 'IconContainer',
})(({ theme }) => ({
  color: theme.palette.text.dark,
}))

export const WishListButton: React.FC<IProductDetailsList> = ({
  currentProduct,
}) => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  const wishlistExternalId = useSelector(wishlistExternalIdSelector)
  const dispatch = useDispatch()
  const theme = useTheme()
  const { data: wishList, isLoading: isWishListLoading } = useGetWishListQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const [addItemToWishList, { isLoading: isLoadingOnAdd }] =
    useAddItemToWishListMutation()
  const [deleteItemFromWishList, { isLoading: isLoadingOnDelete }] =
    useDeleteItemFromWishlistMutation()
  const [triggerCreateWishList] = useLazyCreateWishListQuery()

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    let wishListId: string | undefined

    try {
      if (!currentUser) {
        await dispatch(getGuestIdentity())
        wishListId = await triggerCreateWishList().unwrap()
      } else {
        if (wishList?.GiftList && wishList.GiftList[0]) {
          wishListId = wishList.GiftList[0].uniqueID
        }
        if (!wishListId) {
          wishListId = await triggerCreateWishList().unwrap()
        }
      }

      if (wishListId) {
        const itemInList = wishList?.GiftList?.[0]?.item?.find(
          (item) => item.partNumber === currentProduct.partNumber
        )

        if (itemInList) {
          await deleteItemFromWishList({
            externalId: wishlistExternalId,
            itemId: itemInList.giftListItemID,
          }).unwrap()
        } else {
          await addItemToWishList({
            partNumber: currentProduct.partNumber,
            wishListId,
          }).unwrap()
        }
      }
    } catch (error: any) {
      Log.error('Wishlist error: ', error)
    }
  }

  if (isWishListLoading || isLoadingOnAdd || isLoadingOnDelete) {
    return <StyledLoader size={16} />
  }

  const isInWishlist = Boolean(
    wishList?.GiftList?.[0]?.item?.find(
      (item) => item.partNumber === currentProduct.partNumber
    )
  )

  return (
    <WishlistIcon
      aria-label="Toggle wishlist"
      data-element-id="X_X_Prod_AddToWishList"
      onClick={onClick}
    >
      {isInWishlist ? (
        <HeartFilledIcon
          aria-label="Prodotto nella wishlist"
          htmlColor={theme.palette.primary.main}
          fill={theme.palette.primary.light}
        />
      ) : (
        <HeartIcon
          aria-label="Aggiungi alla wishlist"
          htmlColor={theme.palette.text.dark}
        />
      )}
    </WishlistIcon>
  )
}

export default WishListButton
