export const BASE_COUNTRY_KEY = 'country'
export const BASE = `/:${BASE_COUNTRY_KEY}`
export const HOME = '/'
export const CMSPREVIEW = '/cmpreview'

export const PLAYGROUND = '/playground'

//Order
export const CART = 'cart'
export const MAINTENANCE = '/maintenance'
export const WISHLIST = 'wishlist'
export const PRESCRIPTION_VERIFICATION = 'checkout'
export const STORELOCATOR = 'trova-negozi'
export const NEGOZIO = `${STORELOCATOR}/negozio`
export const BOOKAPPOINTMENT = 'prenota-appuntamento'

//Account
export const SIGNIN = 'sign-in'
export const SIGNUP = 'sign-up'
export const FORGOT_PASSWORD = 'forgot-password'
export const ACCOUNTWITHOUTPARAM = 'account'
export const ACCOUNT = 'account/:page'
export const ACCOUNT_CHILDREN = {
  DASHBOARD: 'dashboard',
  ADDRESS_BOOK: 'indirizzi',
  EDIT_ADDRESS: 'dettagli',
  ORDER_HISTORY: 'storico-ordini',
  PERSONAL_INFORMATION: 'dati-personali',
  SETTINGS: 'impostazioni',
  PAYMENT_METHODS: 'payment-methods',
  STORES: 'negozi',
  FAVOURITES: 'wishlist',
}
//Checkout
export const CHECKOUT = 'checkout'
export const CHECKOUT_CHILDREN = {
  SHIPPING: 'spedizione',
  PAYMENT: 'pagamento',
  ORDER_CONFIRMATION: 'conferma-ordine',
  UPLOAD_PRESCRIPTION: 'carica-prescrizione',
}

// //User
// export const CHANGE_PASSWORD = buildAccountPageRoute(ACCOUNT_CHILDREN.CHANGE_PASSWORD)

//Search
export const SEARCH = '/search'

//Services
export const SITEMAP = 'sitemap'
export const CAREERS = 'lavora-con-noi'
export const TRACK_ORDER = 'traccia-ordine'
export const REORDER_CL = 'reorder'
//register user access only
export const REGISTER_PROTECTED = 'register'
//only guest and generic user access
export const GENERIC_PROTECTED = 'generic'

export const CMS_PAGE_NAME_LV1 = '/:pageNameLv1'
export const CMS_PAGE_NAME_LV2 = '/:pageNameLv2'
export const CMS_PAGE_NAME_LV3 = '/:pageNameLv3'
export const CMS_PAGE_NAME_LV4 = '/:pageNameLv4'
//export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}${CMS_PAGE_NAME_LV2}${CMS_PAGE_NAME_LV3}${CMS_PAGE_NAME_LV4}${CMS_PAGE_NAME_LV5}`
export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}`
