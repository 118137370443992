/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { siteSelector } from '../../redux/selectors/site'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

const StyledTopBar = styled('nav', {
  name: 'TopBar',
  slot: 'NavBar',
})(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  padding: theme.spacing(0, 5),
  zIndex: '10000000',
  paddingRight: theme.spacing(0.75),
  height: theme.spacing(10.75),
  backgroundColor: 'yellow',
  border: '1px solid yellow',
  borderTop: 0,
  boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  //zIndex: 1030,
  color: 'black',
}))

const StyledBtn = styled('button', {
  name: 'TopBar',
  slot: 'Button',
})(({ theme }) => ({
  background: theme.palette.color.white.main,
  color: theme.palette.color.black.main,
  borderColor: theme.palette.color.black.main,
  display: 'inline-block',
  margin: theme.spacing(1.25),
  fontWeight: 400,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  userSelect: 'none',
  border: '1px solid transparent',
  padding: theme.spacing(1.5, 3),
  fontSize: 16,
  lineHeight: 1.25,
  borderRadius: 4,
  transition:
    'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  cursor: 'pointer',
}))

const StyledBtnWarning = styled(StyledBtn, {
  name: 'TopBar',
  slot: 'BtnWarning',
})({
  backgroundColor: '#e0a800',
  borderColor: '#e0a800',
})

const StyledBtnDanger = styled(StyledBtn, {
  name: 'TopBar',
  slot: 'BtnDanger',
})({
  backgroundColor: '#dc3545',
  borderColor: '#dc3545',
  color: 'white',
})

//const hostname = window.location.origin

const TopBar = () => {
  const [isActive, setIsActive] = useState('false')
  const [storeId, setStoreId] = useState('')
  const [orderId, setOrderId] = useState('')
  const [callerId] = useState('')
  const [personalizationID, setPersId] = useState('')
  const [userId, setUserId] = useState('')
  const [forUserId, setForUserId] = useState('')
  const [showLockCartBtn, setShowLockCartBtn] = useState(false)
  const [showUnlockCartBtn, setShowUnlockCartBtn] = useState(false)
  const [showTakeOverCartBtn, setShowTakeOverCartBtn] = useState(false)
  const [adviseCstUserText, setAdviseCstUserText] = useState('')
  const [error] = useState(null)
  const mySite = useSelector(siteSelector)!
  const { langCode } = useStoreIdentity()

  const transactionContext = mySite.transactionContext

  /*  const urlList = {
    setCookie: '/wcs/resources/store/{storeId}/behalf/setNewCookies',
    getCart: '/wcs/resources/store/{storeId}/cart/@self?forUserId={customerId}',
    takeOver:
      '/wcs/resources/store/{storeId}/cart/{orderId}/lockOnBehalf?takeOverLock=Y',
    lockCart:
      '/wcs/resources/store/{storeId}/cart/{orderId}/lockOnBehalf?forUserId={customerId}',
    unlockCart:
      '/wcs/resources/store/{storeId}/cart/{orderId}/unlockOnBehalf?forUserId={customerId}',
    unlockBehalf: '/wcs/resources/store/{storeId}/cart/{orderId}/unlockOnBehalf',
    runAsUserSetInSession:
      '/webapp/wcs/stores/servlet/RunAsUserSetInSession?runAsUserId={memberId}',
    restoreOriginalUserSetInSession:
      '/webapp/wcs/stores/servlet/RestoreOriginalUserSetInSession?storeId={storeId}&URL=AjaxLogonForm&myAcctMain=1',
  }*/
  const urlList = {
    getHCLUserSession: `${transactionContext}/store/{storeId}/person/@self?forUserId={customerId}`,
    setCookie: `${transactionContext}/store/{storeId}/behalf/setNewCookies`,
    getCart: `${transactionContext}/store/{storeId}/cart/@self?forUserId={customerId}`,
    takeOver: `${transactionContext}/store/{storeId}/cart/{orderId}/lockOnBehalf?takeOverLock=Y`,
    lockCart: `${transactionContext}/store/{storeId}/cart/{orderId}/lockOnBehalf?forUserId={customerId}`,
    unlockCart: `${transactionContext}/store/{storeId}/cart/{orderId}/unlockOnBehalf?forUserId={customerId}`,
    unlockBehalf: `${transactionContext}/store/{storeId}/cart/{orderId}/unlockOnBehalf`,
    runAsUserSetInSession:
      '/webapp/wcs/stores/servlet/RunAsUserSetInSession?runAsUserId={memberId}',
    restoreOriginalUserSetInSession:
      '/webapp/wcs/stores/servlet/RestoreOriginalUserSetInSession?storeId={storeId}&URL=AjaxLogonForm&myAcctMain=1',
  }

  //const currentUserSession = storageSessionHandler.getCurrentUserAndLoadAccount()
  const getAbsoluteURL = () => {
    let a
    if (!a) a = document.createElement('a')
    a.href = window.location.href

    return a.href
  }

  // /wcs/resources/store/{storeId}/cart/@self/forUserId={customerId}
  const checkCart = () => {
    const url = urlList['getCart']
      .replace('{storeId}', localStorage.getItem('storeId')!)
      .replace('{customerId}', localStorage.getItem('customerId')!)
    const currentUserSession = JSON.parse(
      sessionStorage?.getItem('HCL--forUserSession')!
    )
    /* const currentUserSession = JSON.parse(
      sessionStorageUtil.get(FOR_USER_SESSION, false)
    )*/
    if (currentUserSession != null) {
      axios({
        url,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          WCPersonalization: currentUserSession.personalizationID,
          WCToken: currentUserSession.WCToken,
          WCTrustedToken: currentUserSession.WCTrustedToken,
        },
      })
        .then((res) => {
          setOrderId(res.data.orderId)
          const lockedCart = res.data.locked

          if (lockedCart === 'true') {
            if (
              localStorage.getItem('callerId') === res.data.orderEditor.uniqueID
            ) {
              // cart it's locked by current user
              setShowLockCartBtn(false)
              setShowUnlockCartBtn(true)
              setAdviseCstUserText('')
              setShowTakeOverCartBtn(false)
            } else {
              // cart it's locked by another CST Agent
              setAdviseCstUserText(
                "Cart it's locked by : " +
                  res.data.orderEdition.externalIdentifier.identifier
              )
              setShowUnlockCartBtn(false)
              setShowLockCartBtn(false)
              setShowTakeOverCartBtn(true)
            }
          } else {
            setShowLockCartBtn(true)
            setShowUnlockCartBtn(false)
            setShowTakeOverCartBtn(false)
            setAdviseCstUserText(
              'Cart not locked by you. Please lock cart to add items.'
            )
          }
        })
        .catch((err) => {
          console.log(err)
          console.log('no cart for user')
          setShowLockCartBtn(false)
          setShowUnlockCartBtn(false)
          setShowTakeOverCartBtn(false)
        })
    }
  }

  // /wcs/resources/store/{storeId}/cart/{orderId}/lockOnBehalf?takeOverLock=Y
  const takeOver = () => {
    const url = urlList['getCart']
      .replace('{storeId}', localStorage.getItem('storeId')!)
      .replace('{customerId}', localStorage.getItem('customerId')!)
    const currentUserSession = JSON.parse(
      sessionStorage.getItem('HCL--forUserSession')!
    )

    if (currentUserSession != null) {
      axios({
        url,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          WCPersonalization: currentUserSession.personalizationID,
          WCToken: currentUserSession.WCToken,
          WCTrustedToken: currentUserSession.WCTrustedToken,
        },
      })
        .then((res) => {
          const orderId = res.data.orderId
          const urlTakeOver = urlList['takeOver']
            .replace('{storeId}', localStorage.getItem('storeId')!)
            .replace('{orderId}', orderId)

          axios({
            url: urlTakeOver,
            method: 'POST',
            data: {},
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
              Accept: 'application/json',
              'Content-Type': 'application/json; charset=utf-8',
              WCPersonalization: currentUserSession.personalizationID,
              WCToken: currentUserSession.WCToken,
              WCTrustedToken: currentUserSession.WCTrustedToken,
            },
          })
            .then(() => {
              checkCart()
            })
            .catch((err) => {
              console.log(err)
              checkCart()
            })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  // /wcs/resources/store/{storeId}/cart/{orderId}/lockOnBehalf?forUserId={customerId}
  const lockCart = () => {
    const url = urlList['getCart']
      .replace('{storeId}', localStorage.getItem('storeId')!)
      .replace('{customerId}', localStorage.getItem('customerId')!)
    const currentUserSession = JSON.parse(
      sessionStorage.getItem('HCL--forUserSession')!
    )

    if (currentUserSession != null) {
      axios({
        url,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          WCPersonalization: currentUserSession.personalizationID,
          WCToken: currentUserSession.WCToken,
          WCTrustedToken: currentUserSession.WCTrustedToken,
        },
      })
        .then((res) => {
          const customerId = res.data.buyerId
          const orderId = res.data.orderId

          const urlLockCart = urlList['lockCart']
            .replace('{storeId}', localStorage.getItem('storeId')!)
            .replace('{orderId}', orderId)
            .replace('{customerId}', customerId)

          axios({
            url: urlLockCart,
            method: 'POST',
            data: {},
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
              Accept: 'application/json',
              'Content-Type': 'application/json; charset=utf-8',
              WCPersonalization: currentUserSession.personalizationID,
              WCToken: currentUserSession.WCToken,
              WCTrustedToken: currentUserSession.WCTrustedToken,
            },
          })
            .then(() => {
              setShowLockCartBtn(false)
              setShowUnlockCartBtn(true)
              setAdviseCstUserText('')
            })
            .catch((err) => {
              console.log(err)
              checkCart()
            })
        })
        .catch((err) => {
          checkCart()
          console.log(err)
          console.log('no cart for user')
        })
    }
  }

  // /wcs/resources/store/{storeId}/cart/{orderId}/unlockOnBehalf?forUserId={customerId}
  const unlockCart = () => {
    const url = urlList['getCart']
      .replace('{storeId}', localStorage.getItem('storeId')!)
      .replace('{customerId}', localStorage.getItem('customerId')!)
    const currentUserSession = JSON.parse(
      sessionStorage.getItem('HCL--forUserSession')!
    )

    if (currentUserSession != null) {
      axios({
        url,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          WCPersonalization: currentUserSession.personalizationID,
          WCToken: currentUserSession.WCToken,
          WCTrustedToken: currentUserSession.WCTrustedToken,
        },
      })
        .then((res) => {
          const customerId = res.data.buyerId
          const orderId = res.data.orderId

          const urlUnlockCart = urlList['unlockCart']
            .replace('{storeId}', localStorage.getItem('storeId')!)
            .replace('{orderId}', orderId)
            .replace('{customerId}', customerId)

          axios({
            url: urlUnlockCart,
            method: 'POST',
            data: {},
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
              Accept: 'application/json',
              'Content-Type': 'application/json; charset=utf-8',
              WCPersonalization: currentUserSession.personalizationID,
              WCToken: currentUserSession.WCToken,
              WCTrustedToken: currentUserSession.WCTrustedToken,
            },
          })
            .then(() => {
              setShowLockCartBtn(true)
              setShowUnlockCartBtn(false)
              setAdviseCstUserText(
                'Cart Not Locked by you. Please lock cart to add items.'
              )
            })
            .catch((err) => {
              console.log(err)
              checkCart()
            })
        })
        .catch((err) => {
          checkCart()
          console.log(err)
          console.log('no cart for user')
        })
    }
  }

  // EXIT BEHALF
  const restoreUser = () => {
    const url = urlList['getCart']
      .replace('{storeId}', localStorage.getItem('storeId')!)
      .replace('{customerId}', localStorage.getItem('customerId')!)
    const currentUserSession = JSON.parse(
      sessionStorage.getItem('HCL--forUserSession')!
    )

    if (currentUserSession != null) {
      axios({
        url,
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          WCPersonalization: currentUserSession.personalizationID,
          WCToken: currentUserSession.WCToken,
          WCTrustedToken: currentUserSession.WCTrustedToken,
        },
      })
        .then((res) => {
          const orderId = res.data.orderId
          const lockedCart = res.data.locked

          if (lockedCart === 'true') {
            if (
              localStorage.getItem('callerId') === res.data.orderEditor.uniqueID
            ) {
              // if the cart it's locked by CST Agent i need to unlock cart
              const urlUnlockBehalf = urlList['unlockBehalf']
                .replace('{storeId}', localStorage.getItem('storeId')!)
                .replace('{orderId}', orderId)

              axios({
                url: urlUnlockBehalf,
                method: 'POST',
                data: {},
                headers: {
                  'Cache-Control': 'no-cache',
                  Pragma: 'no-cache',
                  Expires: '0',
                  Accept: 'application/json',
                  'Content-Type': 'application/json; charset=utf-8',
                  WCPersonalization: currentUserSession.personalizationID,
                  WCToken: currentUserSession.WCToken,
                  WCTrustedToken: currentUserSession.WCTrustedToken,
                },
              }).then(() => {
                // Remove current behalf session
                localStorage.removeItem('beHalfTrue')
                localStorage.removeItem('HCL--personalizationID')
                localStorage.removeItem('storeId')
                localStorage.removeItem('callerId')
                localStorage.removeItem('customerId')
                sessionStorage.removeItem('HCL--forUserSession')
                //window.close()
                window.open(`/${langCode}`, '_self')
                localStorage.clear()
                sessionStorage.clear()
              })
            } else {
              // Remove current behalf session
              localStorage.removeItem('beHalfTrue')
              localStorage.removeItem('HCL--personalizationID')
              localStorage.removeItem('storeId')
              localStorage.removeItem('callerId')
              localStorage.removeItem('customerId')
              sessionStorage.removeItem('HCL--forUserSession')
              //window.close()
              window.open(`/${langCode}`, '_self')
              localStorage.clear()
              sessionStorage.clear()
            }
          } else {
            // Remove current behalf session
            localStorage.removeItem('beHalfTrue')
            localStorage.removeItem('HCL--personalizationID')
            localStorage.removeItem('storeId')
            localStorage.removeItem('callerId')
            localStorage.removeItem('customerId')
            sessionStorage.removeItem('HCL--forUserSession')
            //window.close()
            window.open(`/${langCode}`, '_self')
            localStorage.clear()
            sessionStorage.clear()
          }
          window.open(`/${langCode}`, '_self')
          localStorage.clear()
          sessionStorage.clear()
        })
        .catch((err) => {
          // Remove current behalf session
          localStorage.removeItem('beHalfTrue')
          localStorage.removeItem('HCL--personalizationID')
          localStorage.removeItem('storeId')
          localStorage.removeItem('callerId')
          localStorage.removeItem('customerId')
          sessionStorage.removeItem('HCL--forUserSession')
          //window.close()
          window.open(`/${langCode}`, '_self')
          localStorage.clear()
          sessionStorage.clear()
        })
    }
  }

  useEffect(() => {
    //console.log('>>> Reading query string parameters')
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    //setIsActive(urlParams.get('onBehalfSession') as string)
    if (
      urlParams.get('onBehalfSession') ||
      localStorage.beHalfTrue === 'true'
    ) {
      setIsActive('true' as string)
      document.getElementsByTagName('main')[0].style.marginTop = '43px'
    } else {
      setIsActive('false' as string)
    }

    let beHalfTrue = localStorage.beHalfTrue
    // Enter only if behalf is active and personalizationID and userId are empty
    if (isActive === 'true' && beHalfTrue === undefined) {
      localStorage.setItem('beHalfTrue', 'true')

      console.log('>>> Calling CST Cookies retrieval services')

      localStorage.setItem(
        'HCL--personalizationID',
        urlParams.get('personalizationID') as string
      )
      localStorage.setItem('storeId', urlParams.get('storeId') as string)
      localStorage.setItem('callerId', urlParams.get('userId') as string) //agent
      localStorage.setItem('customerId', urlParams.get('forUserId') as string) //customer

      axios({
        //url:  transactionContext + '/store/' + urlParams.get('storeId') + '/person/@self?forUserId=' + urlParams.get('forUserId'),
        url: urlList.getHCLUserSession
          .replace('{storeId}', urlParams.get('storeId')!)
          .replace('{customerId}', urlParams.get('forUserId')!),
        method: 'GET',
        data: {},
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          WCPersonalization: urlParams.get('personalizationID') as string,
          WCToken: encodeURIComponent(urlParams.get('WCToken') as string),
          WCTrustedToken: encodeURIComponent(
            urlParams.get('WCTrustedToken') as string
          ),
        },
      })
        .then((res) => {
          // HCSEmerald-Emerald-forUserSession model
          let HCLuserSession = {
            WCToken: encodeURIComponent(urlParams.get('WCToken') as string),
            WCTrustedToken: encodeURIComponent(
              urlParams.get('WCTrustedToken') as string
            ),
            details: res.data,
            forUserId: urlParams.get('forUserId') as string,
            logonId: urlParams.get('agentLogonId') as string,
            personalizationID: urlParams.get('personalizationID') as string,
            resourceName: 'loginidentity',
            userId: urlParams.get('userId') as string,
            userLoggedIn: true,
          }

          sessionStorage.setItem(
            'HCL--forUserSession',
            JSON.stringify(HCLuserSession)
          )
          localStorage.setItem('logonIdForUserId', res.data.email1)
        })
        .catch((err) => {
          // @ts-ignore
          console.error('Error Call person/@self?forUserId: ', error.toJSON()!)
        })

      if (!localStorage.reload) {
        setTimeout('document.location.reload()', 2000)
        localStorage.reload = 1
      }
    } //end isActive

    if (isActive === 'true') {
      checkCart()
    }
  })

  return (
    <>
      {isActive === 'true' && (
        <StyledTopBar>
          <span className="navbar-text">
            On behalf of: {localStorage.getItem('logonIdForUserId')}
          </span>
          <span className="navbar-text text-center" id="adviseCstUserText">
            {localStorage.getItem('adviseCstUserText')}
          </span>
          <form>
            <StyledBtn
              type="button"
              className="btn btn-primary"
              onClick={() => restoreUser()}
            >
              Exit CST On Behalf
            </StyledBtn>
            {showLockCartBtn && (
              <StyledBtnDanger
                id="lockCartBtn"
                type="button"
                onClick={() => lockCart()}
              >
                Lock Cart{' '}
              </StyledBtnDanger>
            )}
            {showUnlockCartBtn && (
              <StyledBtnWarning
                id="unlockCartBtn"
                type="button"
                onClick={() => unlockCart()}
              >
                Unlock Cart{' '}
              </StyledBtnWarning>
            )}
            {showTakeOverCartBtn && (
              <StyledBtnWarning
                id="takeOverCartBtn"
                type="button"
                onClick={() => takeOver()}
              >
                Take Over
              </StyledBtnWarning>
            )}
          </form>
          {error && <div className="navbar-error">{error}</div>}
        </StyledTopBar>
      )}
    </>
  )
}

export default TopBar
