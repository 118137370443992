import React from 'react'
import {
  StyledProductBrandName,
  StyledProductDescriptionAnchor,
  StyledProductName,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileFooterWrapper,
  StyledWishListContainer,
} from '@components/ProductTile/components/ProductTileFooter/ProductTileFooter.style'
import { SupplyData } from '../../../../types/product'
import { ProductAlgolia } from '../../../../types/ProductAlgolia/ProductAlgolia'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'
import { WishListButton } from '../../../../pages/ProductDetails/components/WishListButton'
import { useSelector } from 'react-redux'
import {
  wishlistEnabledSelector,
  wishlistGuestEnabledSelector,
} from '../../../../features/wishList/selector'
import { loginStatusSelector } from '../../../../redux/selectors/user'
import { IUiState } from '../../../../features/ui/slice'

export interface ProductTileFooterProps {
  annualSupplyData?: SupplyData
  clusterSelected: ProductAlgolia
  metaData: { [key: string]: string }
  isCompact: boolean
  productListingLayout?: IUiState['productsMobileListingLayout']
}

const ProductTileFooter: React.FC<ProductTileFooterProps> = ({
  clusterSelected,
  metaData,
}) => {
  const userLoggedIn = useSelector(loginStatusSelector) || false
  const isGuestEnabled = useSelector(wishlistGuestEnabledSelector) || false
  const wishListEnabled =
    (useSelector(wishlistEnabledSelector) && isGuestEnabled) ||
    (useSelector(wishlistEnabledSelector) && userLoggedIn)
  const { langCode } = useStoreIdentity()
  const { getLxProductTypes, getProductAttributes } = ProductAlgoliaUtils
  const productPrice = clusterSelected?.prices
  const productLinkTo = `/${langCode}${clusterSelected.url || ''}`
  const attributes = clusterSelected?.attributes_translated

  const { modelName, brand, lxSearchPageProductType, lxSoldOut } =
    getProductAttributes(attributes)

  const { isFrames, isContactLensesAccessories, isContactLenses } =
    getLxProductTypes(lxSearchPageProductType)

  const { isSoldOut } = ProductAlgoliaUtils.getSoldOutStatus(lxSoldOut)

  const showWishButton = () => {
    if (
      wishListEnabled &&
      !isSoldOut &&
      !isContactLensesAccessories &&
      !isContactLenses
    ) {
      return (
        <StyledWishListContainer>
          <WishListButton
            currentProduct={{
              ...clusterSelected,
              id: clusterSelected.productId,
              partNumber: clusterSelected.partnumberId,
            }}
          />
        </StyledWishListContainer>
      )
    }
    return null
  }

  return (
    <StyledProductTileFooterWrapper>
      <StyledProductTileFooter>
        <StyledProductTileDescription>
          {showWishButton()}
          <StyledProductDescriptionAnchor
            to={productLinkTo}
            isClRelatedTile={!isFrames}
            {...metaData}
          >
            <StyledProductName
              type={lxSearchPageProductType}
              aria-describedby={modelName ? modelName : ''}
            >
              {modelName ? modelName : '\u00A0'}
            </StyledProductName>
            <StyledProductBrandName>
              {!(isContactLenses || isContactLensesAccessories) ? (
                <StyledProductBrandName>
                  {brand || 'Brand name'}
                </StyledProductBrandName>
              ) : null}
            </StyledProductBrandName>
            {productPrice ? (
              <ProductPriceFinal
                pageType={'plp'}
                productType={lxSearchPageProductType}
                price={productPrice}
              />
            ) : null}
          </StyledProductDescriptionAnchor>
        </StyledProductTileDescription>
      </StyledProductTileFooter>
    </StyledProductTileFooterWrapper>
  )
}

export default ProductTileFooter
