export const GENERIC_USER_ERROR = 'USR.CWXFR0130E'
export const EXPIRED_ACTIVITY_TOKEN_ERROR = 'CWXBB1011E'
export const INVALID_COOKIE_ERROR_KEY = '_ERR_INVALID_COOKIE'
export const INVALID_COOKIE_ERROR_CODE = 'CMN1039E'
export const SESSION_TIMEOUT_ERROR = 'sessionTimeout'
export const SESSION_INVALID_ERROR = 'sessionInvalid'
export const ACCOUNT_LOCKOUT_ERROR = '2490'
export const CART_LOCKED = '_ERR_ORDER_IS_LOCKED'
export const PASSWORD_EXPIRED = '_ERR_PASSWORD_EXPIRED'
export const PASSWORD_EXPIRED_ERR_CODE = '2170'
export const ACCOUNT_NOT_ACTIVATED = '2450'
export const PARTIAL_AUTHENTICATION_ERROR_CODE = 'CWXFR0222E'
export const PARTIAL_AUTHENTICATION_ERROR_KEY =
  'ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWE'
export const CMC_SESSION_ERROR_KEY = 'CMC_RELOGON'
export const ACTIVITY_TOKEN_ERROR_KEY = '_ERR_ACTIVITY_TOKEN'
export const ACTIVITY_TOKEN_ERROR_CODE = 'CWXBB1103E'
export const CONNECTION_TIMEDOUT_ERROR = 'ECONNABORTED'
export const EXPIRED_COOKIE_ERROR_CODE = 'CWXFR0223E'
export const EXPIRED_COOKIE_ERROR_KEY = 'ERR_COOKIE_EXPIRED'
export const ERROR_EMAIL_ALREADY_REGISTERED = '2030'
export const NEWSLETTER_EMAIL_ALREADY_REGISTERED_ERROR_CODE = 'CMN7000E'
export const TRACK_MY_ORDER_ERROR = 'trackingorder'
export const ERR_ORDERID_HASHED_NOT_MATCH = '_ERR_ORDERID_HASHED_NOT_MATCH'

//PAYMENT
const PAYMENT_ERRORS_KEYS_MAP = {
  PLUGIN_RC_1001: 'PLUGIN_RC_1001',
  PLUGIN_RC_1002: 'PLUGIN_RC_1002',
  PLUGIN_RC_1003: 'PLUGIN_RC_1003',
  PLUGIN_RC_2002: 'PLUGIN_RC_2002',
  PLUGIN_RC_2003: 'PLUGIN_RC_2003',
  PLUGIN_RC_2004: 'PLUGIN_RC_2004',
  PLUGIN_RC_2005: 'PLUGIN_RC_2005',
  PLUGIN_RC_2006: 'PLUGIN_RC_2006',
  PLUGIN_RC_2007: 'PLUGIN_RC_2007',
  PLUGIN_RC_3001: 'PLUGIN_RC_3001',
  PLUGIN_CONFIGURATION_EXCEPTION: 'PLUGIN_CONFIGURATION_EXCEPTION',
  PLUGIN_COMMUNICATION_EXCEPTION: 'PLUGIN_COMMUNICATION_EXCEPTION',
  PLUGIN_INTERNAL_ERROR_EXCEPTION: 'PLUGIN_INTERNAL_ERROR_EXCEPTION',
  PLUGIN_FUNCTION_NOT_SUPPORTED_EXCEPTION:
    'PLUGIN_FUNCTION_NOT_SUPPORTED_EXCEPTION',
  PLUGIN_INVALID_CARD_NUMBER: 'PLUGIN_INVALID_CARD_NUMBER',
  PLUGIN_INVALID_EXPIRY_DATE: 'PLUGIN_INVALID_EXPIRY_DATE',
  PLUGIN_INCORRECT_EXPIRY_DATE: 'PLUGIN_INCORRECT_EXPIRY_DATE',
  PLUGIN_CARD_SUSPENDED: 'PLUGIN_CARD_SUSPENDED',
  PLUGIN_CREDIT_LIMIT_EXCEEDED: 'PLUGIN_CREDIT_LIMIT_EXCEEDED',
  PLUGIN_PAYMENT_TOKEN_VALIDATION_FAILURE:
    'PLUGIN_PAYMENT_TOKEN_VALIDATION_FAILURE',
  PLUGIN_PAYMENT_TOKEN_ACCOUNT_FORBIDDEN:
    'PLUGIN_PAYMENT_TOKEN_ACCOUNT_FORBIDDEN',
  PLUGIN_RC_101: 'PLUGIN_RC_101',
  PLUGIN_RC_102: 'PLUGIN_RC_102',
  PLUGIN_RC_103: 'PLUGIN_RC_103',
  PLUGIN_RC_110: 'PLUGIN_RC_110',
  PLUGIN_RC_150: 'PLUGIN_RC_150',
  PLUGIN_RC_151: 'PLUGIN_RC_151',
  PLUGIN_RC_152: 'PLUGIN_RC_152',
  PLUGIN_RC_200: 'PLUGIN_RC_200',
  PLUGIN_RC_201: 'PLUGIN_RC_201',
  PLUGIN_RC_203: 'PLUGIN_RC_203',
  PLUGIN_RC_204: 'PLUGIN_RC_204',
  PLUGIN_RC_205: 'PLUGIN_RC_205',
  PLUGIN_RC_207: 'PLUGIN_RC_207',
  PLUGIN_RC_208: 'PLUGIN_RC_208',
  PLUGIN_RC_209: 'PLUGIN_RC_209',
  PLUGIN_RC_210: 'PLUGIN_RC_210',
  PLUGIN_RC_211: 'PLUGIN_RC_211',
  PLUGIN_RC_221: 'PLUGIN_RC_221',
  PLUGIN_RC_230: 'PLUGIN_RC_230',
  PLUGIN_RC_231: 'PLUGIN_RC_231',
  PLUGIN_RC_232: 'PLUGIN_RC_232',
  PLUGIN_RC_233: 'PLUGIN_RC_233',
  PLUGIN_RC_234: 'PLUGIN_RC_234',
  PLUGIN_RC_235: 'PLUGIN_RC_235',
  PLUGIN_RC_236: 'PLUGIN_RC_236:',
  PLUGIN_RC_237: 'PLUGIN_RC_237',
  PLUGIN_RC_238: 'PLUGIN_RC_238',
  PLUGIN_RC_239: 'PLUGIN_RC_239',
  PLUGIN_RC_240: 'PLUGIN_RC_240',
  PLUGIN_RC_476: 'PLUGIN_RC_476',
  PLUGIN_RC_480: 'PLUGIN_RC_480',
  PLUGIN_RC_481: 'PLUGIN_RC_481',
  FORTER_REJECTED: 'FORTER_REJECTED',
} as const

export const {
  PLUGIN_RC_1001,
  PLUGIN_RC_1002,
  PLUGIN_RC_1003,
  PLUGIN_RC_2002,
  PLUGIN_RC_2003,
  PLUGIN_RC_2004,
  PLUGIN_RC_2005,
  PLUGIN_RC_2006,
  PLUGIN_RC_2007,
  PLUGIN_RC_3001,
  PLUGIN_CONFIGURATION_EXCEPTION,
  PLUGIN_COMMUNICATION_EXCEPTION,
  PLUGIN_INTERNAL_ERROR_EXCEPTION,
  PLUGIN_FUNCTION_NOT_SUPPORTED_EXCEPTION,
  PLUGIN_INVALID_CARD_NUMBER,
  PLUGIN_INVALID_EXPIRY_DATE,
  PLUGIN_INCORRECT_EXPIRY_DATE,
  PLUGIN_CARD_SUSPENDED,
  PLUGIN_CREDIT_LIMIT_EXCEEDED,
  PLUGIN_PAYMENT_TOKEN_VALIDATION_FAILURE,
  PLUGIN_RC_101,
  PLUGIN_RC_102,
  PLUGIN_RC_103,
  PLUGIN_RC_110,
  PLUGIN_RC_150,
  PLUGIN_RC_151,
  PLUGIN_RC_152,
  PLUGIN_RC_200,
  PLUGIN_RC_201,
  PLUGIN_RC_203,
  PLUGIN_RC_204,
  PLUGIN_RC_205,
  PLUGIN_RC_207,
  PLUGIN_RC_208,
  PLUGIN_RC_209,
  PLUGIN_RC_210,
  PLUGIN_RC_211,
  PLUGIN_RC_221,
  PLUGIN_RC_230,
  PLUGIN_RC_231,
  PLUGIN_RC_232,
  PLUGIN_RC_233,
  PLUGIN_RC_234,
  PLUGIN_RC_235,
  PLUGIN_RC_236,
  PLUGIN_RC_237,
  PLUGIN_RC_238,
  PLUGIN_RC_239,
  PLUGIN_RC_240,
  PLUGIN_RC_476,
  PLUGIN_RC_480,
  PLUGIN_RC_481,
  FORTER_REJECTED,
} = PAYMENT_ERRORS_KEYS_MAP
