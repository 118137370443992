import { styled } from '@mui/material/styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const WrapperBrandIcon = styled('div', {
  name: 'BrandIcon',
  slot: 'Wrapper',
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'height' && prop !== 'isTabletLandscape',
})<{
  width?: number
  height?: number
  isTabletLandscape?: boolean
}>(({ theme, width, height, isTabletLandscape }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: width ? width : 'auto',
  minWidth: width ? width : 'auto',
  height: height ? height : 'auto',
  minHeight: height ? height : 'auto',

  [theme.breakpoints.up('md')]: {
    width: isTabletLandscape ? '100%' : width ? width : 'auto',
    height: height ? height : 'auto',
    minHeight: height ? height : 'auto',
  },
}))

export const BrandImg = styled(LazyLoadImage, {
  name: 'BrandIcon',
  slot: 'Image',
  shouldForwardProp: (prop: string) =>
    prop !== 'isInverted' &&
    prop !== 'isPradaLineaRossa' &&
    prop !== 'isScuderiaFerrari' &&
    prop !== 'isArmaniExchange',
})<{
  isInverted: boolean
  isPradaLineaRossa?: boolean
  isScuderiaFerrari?: boolean
  isArmaniExchange?: boolean
}>(
  ({
    theme,
    isInverted,
    isPradaLineaRossa,
    isScuderiaFerrari,
    isArmaniExchange,
  }) => ({
    width: isArmaniExchange ? '75%' : '100%',
    marginLeft: isArmaniExchange ? '10px' : '',
    marginRight: isArmaniExchange ? '10px' : '',
    maxWidth: '100%',
    opacity: 1,
    transition: 'opacity 0.25s',
    filter:
      isInverted && !isPradaLineaRossa ? 'brightness(0) invert(1)' : 'none',
    padding: theme.spacing(0, isScuderiaFerrari ? 3.75 : 0),

    [theme.breakpoints.up('md')]: {
      opacity: isInverted ? 1 : 0.54,
    },

    '&:hover': {
      opacity: 1,
    },
  })
)

export const StyledTopPageTitle = styled('h2', {
  name: 'TopSEOBlock',
  slot: 'Title',
})(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 20,
  color: theme.palette.text.dark,
  textAlign: 'center',
}))
