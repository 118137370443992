import React, { useEffect } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { GUEST_LOGIN_SUCCESS_ACTION } from 'src/redux/actions/user'
import guestIdentityService from 'src/foundation/apis/transaction/guestIdentity.service'
import { storageSessionHandler } from 'src/foundation/utils/storageUtil'
import { useReorderCartMutation } from 'src/features/order/query_new'
import { CART, HOME } from 'src/constants/routes'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'

const Reorder: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { langCode } = useStoreIdentity()

  const orderId = searchParams.get('orderId')
  const orderIdHashed = searchParams.get('orderIdHashed')
  const [reorderCartItems, { isLoading }] = useReorderCartMutation()

  const triggerReorder = async () => {
    if (!orderId || !orderIdHashed) {
      navigate(generatePath(HOME, { country: langCode }))
      return
    }

    try {
      let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
      if (!currentUser) {
        const guestResponse = await guestIdentityService.login({})
        dispatch(GUEST_LOGIN_SUCCESS_ACTION(guestResponse.data))
        currentUser = guestResponse.data
      }

      const res = await reorderCartItems({
        orderId,
        orderIdHashed,
      }).unwrap()

      if (res.viewTaskName === 'RedirectView') {
        navigate(generatePath(`/:country/${CART}`, { country: langCode }))
      } else {
        navigate(generatePath(HOME, { country: langCode }))
      }
    } catch (error) {
      navigate(generatePath(HOME, { country: langCode }))
    }
  }

  useEffect(() => {
    if (!orderId || !orderIdHashed) {
      navigate(generatePath(HOME, { country: langCode }))
    } else {
      triggerReorder()
    }
  }, [orderId, orderIdHashed])

  return (
    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f5f5f5">
      {isLoading && <Skeleton />}
    </SkeletonTheme>
  )
}

export default Reorder
