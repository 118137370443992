import { elasticSearchApi } from '../elasticSearchApi'
import {
  IWishlist,
  AddItemToWishlistResponse,
  UpdateWishlistResponse,
} from '../../types/wishlist'

export const wishListApi = elasticSearchApi.injectEndpoints({
  endpoints: (builder) => ({
    getWishList: builder.query<IWishlist, void>({
      query: () => ({
        url: '/store/{storeId}/wishlist/@default',
        method: 'GET',
      }),
      providesTags: ['WishList'],
    }),
    createWishList: builder.query<string, void>({
      query: () => ({
        url: '/store/{storeId}/wishlist',
        method: 'POST',
        body: {
          description: 'Wish List',
          descriptionName: 'Wish List',
          registry: 'false',
        },
      }),
    }),
    addItemToWishList: builder.mutation<
      AddItemToWishlistResponse,
      { partNumber: string; wishListId: string }
    >({
      query: (args) => ({
        url: `/store/{storeId}/wishlist/${args.wishListId}?addItem=true`,
        method: 'PUT',
        body: {
          item: [
            {
              partNumber: args.partNumber,
              quantityRequested: '1',
              location: 'online',
            },
          ],
        },
      }),
      invalidatesTags: ['WishList'],
    }),
    deleteItemFromWishlist: builder.mutation<
      UpdateWishlistResponse,
      { externalId: string; itemId: string }
    >({
      query: (args) => ({
        url: `/store/{storeId}/wishlist/${args.externalId}?itemId=${args.itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WishList'],
    }),
  }),
})

export const {
  useGetWishListQuery,
  useAddItemToWishListMutation,
  useDeleteItemFromWishlistMutation,
  useLazyCreateWishListQuery,
} = wishListApi
